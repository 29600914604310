console.log("bundle.js is connected");
// // Import polyfill for ie 11
// import "babel-polyfill";
// // Bundle jQuerry
// // var jQuery = require("jquery");
// // window.$ = window.jQuery = jQuery;

// global.$ = require("jquery");
// global.jQuery = global.$;

// // Bundle Greensock
// var gsap = require("gsap");

// // Glide: minimalistic slider/carousel module
// import Glide from "@glidejs/glide";
// window.Glide = Glide;

import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

import Swiper from 'swiper/bundle'
window.Swiper = Swiper

import priorityPlus from 'priority-plus';
window.priorityPlus = priorityPlus

import dragScroll from 'dragscroll';
window.dragScroll = dragScroll

import DatePicker from "tui-date-picker";
window.DatePicker = DatePicker;
